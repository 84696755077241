/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import GlobalContextProvider from "context/GlobalContextProvider"
// import { CookiesProvider } from "react-cookie"

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
  // return (
  //   <CookiesProvider>
  //     <GlobalContextProvider>{element}</GlobalContextProvider>
  //   </CookiesProvider>
  // )
}
