import React, { useEffect } from "react"
import useFirebase from "components/useFirebase"
import uuid from "uuid"
import { useCookies } from "react-cookie"

import validate from "components/Checkout/CheckoutValidation"
import moment from "moment"
export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const maxUsers = 26
const initialState = {
  uuid: uuid.v4(),
  maxUsers: maxUsers,
  activeStep: 0,
  headcount: 0,
  waitlist: false,
  conscent: false,
  roomcount: -99,
  guests: [],
  submitted: false,
  paid: false,
  years: {},
  groups: [],
  selectedGroup: {},
  checkoutErrors: [],
}

// function setYears(groups) {
//   //Setup year list
//   const years = {}
//   for (var group of groups) {
//     if (group && group.year) {
//       years[group.year] = true
//     }
//   }
//   //When we only have groups in the same year, add another tab with message: new groups to come
//   if (Object.keys(years).length === 1) {
//     const year = Number(Object.keys(years)[0]) + 1
//     years[year] = false
//   }
//   initialState.years = years
// }

function reducer(state, action) {
  switch (action.type) {
    case "SET_UUID": {
      return { ...state, uuid: action.value }
    }
    case "SET_GROUPS": {
      console.log("HELLO WORLD")
      const groups = action.groups
      console.log(groups)

      //Setup year list
      var years = {}
      for (var group of groups) {
        if (group && group.year) {
          years[group.year] = true
        }
      }
      //When we only have groups in the same year, add another tab with message: new groups to come
      if (Object.keys(years).length === 1) {
        const year = Number(Object.keys(years)[0]) + 1
        years[year] = false
      }

      //   console.log("___GROUPS___:", groups)
      // groups.years = years
      return {
        ...state,
        groups: groups,
        years: years,
      }
    }
    case "RESET": {
      return {
        ...state,
        uuid: uuid.v4(),
        headcount: 0,
        waitlist: false,
        conscent: false,
        roomcount: -99,
        guests: [],
        submitted: false,
        paid: false,
        selectedGroup: {},
      }
    }
    case "PREV_STEP": {
      return {
        ...state,
        activeStep: state.activeStep - 1,
      }
    }
    case "NEXT_STEP": {
      return {
        ...state,
        activeStep: state.activeStep + 1,
      }
    }
    case "FINAL_STEP": {
      return {
        ...state,
        activeStep: 3,
      }
    }
    case "UPDATE_HEADCOUNT": {
      const count = action.value
      state.waitlist = false
      state.conscent = false
      state.checkoutErrors = []
      //Initialize guest object, any field needs to be validated has to be set to null
      for (var i = 0; i < count; i++) {
        if (!state.guests[i]) {
          state.guests[i] = {
            zhName: null,
            enName: null,
            email: null,
            mobile: null,
            uniqueID: uuid.v4(),
          }
        }
      }
      const group = state.selectedGroup
      // const group = state.groups.filter(
      //   g => g.groupName === state.selectedGroup.groupName
      // )[0]
      const openSpace = group.groupOpen.match(/\d+/)
      if (
        (openSpace && openSpace[0] < Number(count)) ||
        group.groupOpen === "已額滿"
      ) {
        state.waitlist = true
      }
      return {
        ...state,
        headcount: count,
      }
    }
    case "UPDATE_ROOMCOUNT": {
      return {
        ...state,
        roomcount: action.value,
      }
    }
    case "UPDATE_GUEST": {
      let errors = state.checkoutErrors
      state.guests = state.guests.map((obj, i) => {
        if (i === action.index) {
          state.guests[i] = { ...obj, [action.field]: action.value }
          errors = validate(state, i, action.field)
          return { ...obj, [action.field]: action.value }
        }
        return obj
      })

      return {
        ...state,
        // guests: state.guests.map((obj, i) => {
        //   if (i === action.index) {
        //     return { ...obj, [action.field]: action.value }
        //   }
        //   return obj
        // }),
        checkoutErrors: errors,
      }
    }
    case "UPDATE_ERRORS": {
      return {
        ...state,
        checkoutErrors: action.errors,
      }
    }
    case "SET_SELECTED": {
      // let group = {}
      const group = state.groups.filter(
        (g) => g && g.groupName === action.value
      )[0]

      const display =
        group.groupName +
        " (" +
        moment(group.groupStart).format("M/D") +
        " - " +
        moment(group.groupEnd).format("M/D") +
        ")"

      const room = group.groupType === "blachford" ? -99 : state.roomcount
      return {
        ...state,
        // selectedGroupName: action.value,
        selectedGroup: group,
        groupDisplay: display,
        activeStep: 0,
        headcount: 0,
        roomcount: room,
        waitlist: false,
        conscent: false,
      }
    }
    case "CALLBACK": {
      //set selectedGroup from callback URL
      console.log("CALLBACK: ", state)
      const group = state.groups.filter(
        (g) => g && g.uniqueID === action.gid
      )[0]

      //   console.log(state.groups)
      //   const display =
      //     group.groupName +
      //     " (" +
      //     moment(group.groupStart).format("M/D") +
      //     " - " +
      //     moment(group.groupEnd).format("M/D") +
      //     ")"

      return {
        ...state,
        selectedGroup: group,
        activeStep: Number(action.activeStep),
        headcount: Number(action.headcount),
        roomcount: Number(action.roomcount),
        waitlist: action.waitlist || false,
        conscent: false,
        // groupDisplay: display,
      }
    }
    case "CONSCENT": {
      return {
        ...state,
        conscent: action.value,
      }
    }
    case "SUBMIT": {
      const output = {
        ...state,
        submitted: true,
        submitTimestamp: new Date().getTime(),
      }

      var xhr = new XMLHttpRequest()

      xhr.open(
        "POST",
        "https://script.google.com/macros/s/AKfycbz9Ryl5rfGN2UIZTS_xcfJzzxC-E8xAJv2zELeFxlkfnSVlMMy1/exec"
      )
      // xhr.setRequestHeader("Access-Control-Allow-Origin", "*")
      // xhr.setRequestHeader("Access-Control-Allow-Methods", "*")
      // xhr.setRequestHeader("Access-Control-Allow-Methods", "Content-Type")
      // xhr.setRequestHeader("Content-Type", "application/json")

      xhr.send(JSON.stringify(output))

      return output
    }
    default:
      return { state }
  }
}

const GlobalContextProvider = ({ children }) => {
  const cookieFields = [
    "headcount",
    "guests",
    "submitted",
    "paid",
    "groups",
    "years",
  ]
  const [cookies, setCookies] = useCookies(["ynltour"])
  const firebase = useFirebase()

  console.log("COOKIE: ", cookies)
  for (let field of cookieFields) {
    if (cookies && cookies[field]) {
      let value = cookies[field]
      if (value === "true") value = true
      if (value === "false") value = false

      initialState[field] = value
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState)
  console.log("SS: ", state)

  //update cookie as state values are changed
  useEffect(() => {
    for (let field of cookieFields) {
      if (!cookies[field] || cookies[field] !== state[field]) {
        setCookies(field, state[field], { path: "/" })
      }
    }
  }, [state, cookieFields, cookies, setCookies])

  useEffect(() => {
    console.log("FIREBASE: ", firebase)
    if (!firebase) return
    firebase
      .database()
      .ref("POCtest/groups/metadata")
      .once("value")
      .then((snapshot) => {
        state.groups = snapshot.val()
        dispatch({ type: "SET_GROUPS", groups: snapshot.val() })
      })
  }, [firebase])

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
