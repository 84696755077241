import firebase from "firebase/app"
import "firebase/database"

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
}

let instance = null
export default function getFirebase() {
  if (typeof window !== "undefeind") {
    if (instance) return instance
    instance = firebase.initializeApp(config)
    return instance
  }
  return null
}
