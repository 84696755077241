const validation = (guest, error, fields) => {
  if (!error) error = {}
  for (var f = 0; f < fields.length; f++) {
    const field = fields[f]
    switch (field) {
      case "zhName":
        if (!guest[field]) {
          error[field] = "請輸入中文姓名，必填"
        } else {
          delete error[field]
        }
        break
      case "enName":
        if (!guest[field]) {
          error[field] = "請輸入英文姓名，必填"
        } else {
          delete error[field]
        }
        break
      case "email":
        if (!guest[field]) {
          error[field] = "請輸入聯絡Email，必填"
        } else if (!/\S+@\S+\.\S+/.test(guest[field])) {
          error[field] = "Email格式有誤"
        } else {
          delete error[field]
        }
        break
      case "mobile":
        if (!guest[field]) {
          error[field] = "請輸入聯絡電話，必填"
        } else {
          delete error[field]
        }
        break
    }
  }
  if (Object.keys(error).length === 0) {
    error = null
  }
  return error
}

export default function validate(state, guestIndex, check) {
  let errors = state.checkoutErrors.slice()
  if (guestIndex >= 0) {
    const guest = state.guests[guestIndex]
    const fields = [check]
    const error = validation(guest, errors[guestIndex], fields)
    if (error) {
      errors[guestIndex] = error
    } else {
      delete errors[guestIndex]
    }
  } else {
    const fields = ["zhName", "enName", "email", "mobile"]
    for (var i = 0; i < state.headcount; i++) {
      const guest = state.guests[i]
      const error = validation(guest, errors[i], fields)
      if (error) {
        errors[i] = error
      } else {
        delete errors[i]
      }
    }
  }
  return errors
}
