// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blachford-js": () => import("./../src/pages/Blachford.js" /* webpackChunkName: "component---src-pages-blachford-js" */),
  "component---src-pages-checkout-js": () => import("./../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-yk-5-days-js": () => import("./../src/pages/YK5days.js" /* webpackChunkName: "component---src-pages-yk-5-days-js" */)
}

